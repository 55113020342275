<template>
  <v-dialog
    v-model="drawer"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <v-card height="100vh" style="overflow: hidden;" v-if="!selectedFilter">
      <v-toolbar color="secondary" class="mobile-filter-header">
        <v-toolbar-title class="primary--text">
          Filter
        </v-toolbar-title>
        <v-btn icon @click="drawer = false">
          <v-icon color="primary">fal fa-times</v-icon>
        </v-btn>
      </v-toolbar>
      <v-container
        fluid
        class="mobile-filter-container"
        style="height: calc(100vh - 56px); overflow-y: auto;"
      >
        <v-list two-line class="pa-0">
          <v-flex xs12 sm4 class="text-right">
            <div class="favorite-influencer-wrapper mb-5">
              <v-icon
                color="error"
                v-if="internalFilters.favorite"
                @click="toggleFavourite(false)"
                >fas fa-heart</v-icon
              >
              <v-icon color="error" v-else @click="toggleFavourite(true)"
                >fal fa-heart</v-icon
              >
              <span>
                Only my favourites
              </span>
            </div>
          </v-flex>
          <div v-for="item in items" :key="item.text">
            <v-list-item-content @click="chosenFilter(item)">
              <v-list-item-subtitle class="caption">
                <div>
                  {{ item.text }}
                </div>
                <v-icon>fa fa-angle-right</v-icon>
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-divider></v-divider>
          </div>
          <div
            v-if="!selectedFilter && isFilterChips"
            class="mobile-chips-wrapper"
          >
            <div class="advanced-filter remove-filter" @click="cleanFilters">
              <span>
                Remove filters
              </span>
              <v-icon small color="#4F4F4F">fal fa-trash-alt</v-icon>
            </div>
            <filtered-chips
              v-if="isFilterChips"
              :categories="categories"
              :ages="ageItems"
              :filters="cloneDeep(internalFilters)"
              @remove="removeChip($event)"
            ></filtered-chips>
          </div>
        </v-list>
        <v-btn color="primary" class="mobile-btn" @click="save">
          Filter
        </v-btn>
      </v-container>
    </v-card>
    <v-flex v-if="selectedFilter" lg9 sm8 xs12>
      <v-card
        min-height="100vh"
        flat
        class="m-0 filter-container"
        style="overflow-y: scroll;"
      >
        <v-toolbar color="secondary" class="mobile-filter-header">
          <v-toolbar-title class="primary--text">
            Filter
          </v-toolbar-title>
          <v-btn icon @click="drawer = false">
            <v-icon color="primary">fal fa-times</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-title class="p-2 filter-header">
          <v-list-item v-if="selectedFilter" class="text-truncate">
            <v-icon class="mr-4" @click="selectedFilter = false">
              fal fa-arrow-left
            </v-icon>
            <div>
              {{ filterName }}
            </div>
          </v-list-item>
        </v-card-title>
        <v-divider></v-divider>
        <v-list-item-content class="checkbox-wrap custom-selection">
          <v-radio-group
            v-if="filterValue === 'gender'"
            v-model="internalFilters[filterValue]"
            mandatory
          >
            <v-radio
              v-for="data in genderItems"
              :key="data.text"
              :value="data"
              :label="data.text"
            ></v-radio>
          </v-radio-group>
          <v-range-slider
            v-else-if="filterValue === 'followers'"
            :value="[0, 1]"
            min="0"
            max="5"
            ticks="always"
            height="100"
            v-model="initRange"
            :tick-labels="ticksRange"
            tick-size="5"
            class="align-center range-slider"
          >
            <template v-slot:prepend>
              Min
            </template>
            <template v-slot:append>
              Max
            </template>
          </v-range-slider>

          <div
            class="text-wrapper advanced-filter-wrapper"
            v-else-if="filterValue === 'cities'"
          >
            <div class="heading pt-2 pb-4">Follower location</div>
            <div class="descr pb-4">
              Choose which cities the influencer's followers should be based in
            </div>
            <city-select v-model="internalFilters.cities"></city-select>
          </div>

          <div
            class="text-wrapper advanced-filter-wrapper"
            v-else-if="filterValue === 'age_genders'"
          >
            <div class="heading pt-2 pb-4">
              Follower gender and age distribution
            </div>
            <div class="descr pb-4">
              Choose which gender and age distribution the followers of the influencer should have
            </div>
            <age-gender-select
              v-model="internalFilters.age_genders"
            ></age-gender-select>
          </div>
          <v-checkbox
            v-else
            v-for="data in checkboxData"
            multiple
            v-model="internalFilters[filterValue]"
            :key="data.text"
            :value="data.value"
            :label="data.text"
          />
        </v-list-item-content>

        <v-btn
          color="primary"
          class="mobile-btn bla"
          @click="selectedFilter = false"
        >
          Filter
        </v-btn>
      </v-card>
    </v-flex>
  </v-dialog>
</template>
<script>
import { mapActions } from "vuex";
import FilteredChips from "@/components/common/filters/FilteredChips";
import CitySelect from "@/components/selects/CitySelect";
import AgeGenderSelect from "@/components/selects/AgeGenderSelect";
export default {
  components: {
    CitySelect,
    AgeGenderSelect,
    FilteredChips
  },
  props: {
    value: {
      type: Boolean,
      required: false,
      default: false
    },
    filters: {
      required: true,
      type: Object
    },
    categories: {
      required: true,
      type: Array
    },
    genderItems: {
      required: true,
      type: Array
    },
    ageItems: {
      required: true,
      type: Array
    },
    ticksRange: {
      required: true,
      type: Array
    },
    ticksNumberRange: {
      required: true,
      type: Array
    },
    range: {
      required: true,
      type: Array
    }
  },
  data: () => ({
    tab: null,
    initRange: [],
    selectedFilter: null,
    checkboxData: [],
    filterValue: null,
    internalFilters: {
      favorite: false
    },
    filterName: null,
    items: [
      {
        text: "Gender",
        value: "gender"
      },
      {
        text: "Categories",
        value: "category_ids"
      },
      {
        text: "Influencer age",
        value: "age_ranges"
      },
      {
        text: "Number of followers",
        value: "followers"
      },
      {
        text: "Follower cities",
        value: "cities"
      },
      {
        text: "Follower age distribution",
        value: "age_genders"
      }
    ]
  }),

  watch: {
    filters: {
      immediate: true,
      deep: true,
      handler(newVal) {
        this.internalFilters = { ...this.cloneDeep(newVal), favorite: false };
      }
    },
    initRange() {
      const min = this.initRange[0];
      const max = this.initRange[1];
      this.internalFilters.min_followers = this.ticksNumberRange[min];
      this.internalFilters.max_followers = this.ticksNumberRange[max];
    }
  },
  computed: {
    drawer: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      }
    },
    isFilterChips() {
      const filters = this.cloneDeep(this.internalFilters);
      return (
        !!Object.keys(filters).length &&
        ((filters.category_ids && filters.category_ids.length) ||
          (filters.gender && Object.keys(filters.gender).length) ||
          (filters.age_ranges && filters.age_ranges.length) ||
          filters.max_followers ||
          (filters.age_genders && filters.age_genders.length) ||
          (filters.cities && filters.cities.length))
      );
    }
  },
  methods: {
    ...mapActions("subscriptions", ["setShowSubscriptionDialog"]),
    toggleFavourite(value) {
      this.internalFilters.favorite = value;
    },
    removeChip(event) {
      let idx = null;
      const filters = this.cloneDeep(this.internalFilters);

      if (event.item === "gender") {
        delete filters["gender"];
      } else if (event.item === "followers") {
        delete filters["min_followers"];
        delete filters["max_followers"];
      } else {
        idx = filters[event.item].findIndex(filter => filter.id === event.id);
        filters[event.item].splice(idx, 1);
      }
      this.internalFilters = filters;
    },
    saveFilters() {
      this.$emit("saveFilters");
    },
    save() {
      const params = ["category_ids", "cities", "age_genders"];
      params.forEach(param => {
        if (
          param in this.internalFilters &&
          this.internalFilters[param].length === 0
        ) {
          delete this.internalFilters[param];
        }
      });
      this.$emit("saved", this.cloneDeep(this.internalFilters));
      this.saveFilters();
      this.drawer = false;
    },
    cancel() {
      this.drawer = false;
    },
    cleanFilters() {
      const filters = this.cloneDeep(this.internalFilters);
      const filteredKeys = [
        "age_ranges",
        "gender",
        "min_followers",
        "max_followers",
        "category_ids",
        "age_genders",
        "cities",
        "favorite"
      ];
      filteredKeys.forEach(key => {
        if (filters[key]) delete filters[key];
      });
      this.internalFilters = filters;
      this.saveFilters();
    },
    chosenFilter(item) {
      this.selectedFilter = true;
      this.filterName = item.text;
      this.filterValue = item.value;
      this.checkboxData =
        item.value === "category_ids"
          ? this.categories
          : item.value === "age_ranges"
          ? this.ageItems
          : null;
    }
  },
  created() {
    if (this.range) {
      this.initRange = this.range;
    }
  }
};
</script>

<style lang="scss">
.mobile-filter-header .v-toolbar__content {
  display: flex;
  justify-content: space-between;
  width: 65%;
  margin-left: auto;
}
.mobile-filter-container {
  padding: 12px;
  .v-list {
    padding-bottom: 100px !important;
  }
  .v-list-item__subtitle.caption {
    display: flex;
    justify-content: space-between;
    padding: 0 12px;
    div {
      color: var(--v-primary-base);
      font-size: 16px;
    }
    .v-icon {
      font-weight: 400;
    }
  }
}
.filter-header {
  div,
  .v-icon {
    font-weight: 500;
    font-size: 16px;
    color: var(--v-primary-base);
  }
}
.checkbox-wrap {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 10px 20px;
}
.mobile-btn {
  width: 90%;
  margin: 0 auto;
  position: absolute;
  bottom: 40px;
  left: 0;
  right: 0;
}
.v-input--range-slider {
  width: 100%;
}
</style>
