var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-layout',{staticClass:"chips-wrap",attrs:{"wrap":""}},[_c('v-row',[(_vm.filters.gender && _vm.filters.gender.id)?_c('div',{staticClass:"chip-row"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-chip',_vm._g({staticClass:"custom-chip gender-chip",attrs:{"close":"","outlined":"","close-icon":"fa fa-times","text-color":"teal","color":"teal"},on:{"click:close":function($event){return _vm.removeChip('gender', _vm.filters.gender.id)}}},on),[_c('v-icon',{staticClass:"mr-1 custom-chip-icon",attrs:{"color":"teal"}},[_vm._v("fal fa-user")]),_vm._v(" "+_vm._s(_vm.filters.gender.text)+" ")],1)]}}],null,false,2221693403)},[_c('span',[_vm._v("Influencer gender")])])],1):_vm._e(),_vm._l((_vm.filters.category_ids),function(category_id){return _c('div',{key:category_id,staticClass:"chip-row"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-chip',_vm._g({staticClass:"custom-chip gender-chip",attrs:{"close":"","outlined":"","close-icon":"fa fa-times","text-color":"teal","color":"teal"},on:{"click:close":function($event){return _vm.removeChip('category_ids', category_id)}}},on),[_c('v-icon',{staticClass:"mr-1 custom-chip-icon"},[_vm._v("fal fa-user")]),_vm._v(" "+_vm._s(_vm.getCategoryTextById(category_id))+" ")],1)]}}],null,true)},[_c('span',[_vm._v("Influencer category")])])],1)}),_vm._l((_vm.filters.age_ranges),function(age_id){return _c('div',{key:age_id,staticClass:"chip-row"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-chip',_vm._g({staticClass:"custom-chip gender-chip",attrs:{"close":"","outlined":"","text-color":"teal","close-icon":"fa fa-times","color":"teal"},on:{"click:close":function($event){return _vm.removeChip('age_ranges', age_id)}}},on),[_c('v-icon',{staticClass:"mr-1 custom-chip-icon"},[_vm._v("fal fa-user")]),_vm._v(" Infl. age "+_vm._s(_vm.getAgeTextById(age_id))+" ")],1)]}}],null,true)},[_c('span',[_vm._v("Influencer age")])])],1)}),(_vm.filters.max_followers || _vm.filters.min_followers)?_c('div',{staticClass:"chip-row"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-chip',_vm._g({staticClass:"custom-chip gender-chip",attrs:{"close":"","outlined":"","text-color":"teal","close-icon":"fa fa-times","color":"teal"},on:{"click:close":function($event){return _vm.removeChip('followers')}}},on),[_c('v-icon',{staticClass:"mr-1 custom-chip-icon"},[_vm._v("fal fa-user")]),_c('span',{staticClass:"mr-1"},[_vm._v("Followers")]),(_vm.filters.min_followers || _vm.filters.min_followers === 0)?_c('span',[_vm._v(" "+_vm._s(_vm.filters.min_followers.toLocaleString())+" ")]):_vm._e(),_c('span',{staticClass:"mx-1"},[_vm._v(" - ")]),(_vm.filters.max_followers)?_c('span',[_vm._v(" "+_vm._s(_vm.filters.max_followers.toLocaleString())+" ")]):_c('span',[_vm._v("100 000")])],1)]}}],null,false,1672082756)},[_c('span',[_vm._v("Influencer followers")])])],1):_vm._e(),_vm._l((_vm.filters.age_genders),function(item){return _c('div',{key:item.id,staticClass:"chip-row"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-chip',_vm._g({staticClass:"custom-chip advanced-chip",attrs:{"close":"","outlined":"","text-color":"secondary","close-icon":"fa fa-times","color":"secondary"},on:{"click:close":function($event){return _vm.removeChip('age_genders', item.id)}}},on),[_c('v-icon',{staticClass:"mr-1 advanced-chip-icon"},[_vm._v("fal fa-users")]),_vm._v(" "+_vm._s(item.name)+" ")],1)]}}],null,true)},[_c('span',[_vm._v("Follower age and gender")])])],1)}),_vm._l((_vm.filters.cities),function(item){return _c('div',{key:item.id,staticClass:"chip-row"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-chip',_vm._g({staticClass:"custom-chip advanced-chip",attrs:{"close":"","outlined":"","close-icon":"fa fa-times","text-color":"secondary","color":"secondary"},on:{"click:close":function($event){return _vm.removeChip('cities', item.id)}}},on),[_c('v-icon',{staticClass:"mr-1 advanced-chip-icon"},[_vm._v("fal fa-users")]),_vm._v(" "+_vm._s(item.city_name)+" ")],1)]}}],null,true)},[_c('span',[_vm._v("Follower location")])])],1)})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }