<template>
  <v-dialog v-model="showing" width="100%" max-width="500px">
    <v-form @submit.prevent="save()" class="advanced-filter-wrapper">
      <v-card>
        <v-card-title>
          <div class="headline">
            Advanced search
          </div>
          <v-spacer></v-spacer>
          <v-btn text small @click="cancel()">
            <v-icon color="primary">fal fa-times</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <div class="text-wrapper">
            <div class="heading pt-2 pb-4">Follower location</div>
            <div class="descr">Choose which cities the influencers' followers should be located in</div>
            <city-select v-model="internalFilters.cities"></city-select>
          </div>

          <div class="text-wrapper pt-4">
            <div class="heading pt-2 pb-4">Follower age- and gender distribution</div>
              <div class="descr pb-4">Choose influencer's follower age- and gender distribution</div>
            <age-gender-select
              v-model="internalFilters.age_genders"
            ></age-gender-select>
          </div>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <subscription-crown-icon
            v-if="! hasSubscription"
            :size="12"
            class="mr-2"
          ></subscription-crown-icon>

          <v-btn color="primary" type="submit" @click="save()" :disabled="! hasSubscription">
            Filter
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>
<script>
import {mapGetters, mapState} from "vuex";
import CitySelect from "../selects/CitySelect";
import AgeGenderSelect from "../selects/AgeGenderSelect";
import SubscriptionCrownIcon from "../business/icon/SubscriptionCrownIcon";

export default {
  components: {
    SubscriptionCrownIcon,
    AgeGenderSelect,
    CitySelect
  },
  props: {
    value: {
      type: Boolean,
      required: false,
      default: false
    },
    filters: {
      required: true,
      type: Object
    }
  },
  data: () => ({
    dialog: false,
    internalFilters: null
  }),
  computed: {
    ...mapState("core/auth", ["user"]),
    ...mapGetters("subscriptions", ["hasSubscription"]),
    showing: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      }
    }
  },
  watch: {
    filters: {
      immediate: true,
      deep: true,
      handler(newVal) {
        this.internalFilters = this.cloneDeep(newVal);
      }
    }
  },
  methods: {
    save() {
      const params = ["category_ids", "cities", "age_genders"];
      params.forEach(param => {
        if (
          param in this.internalFilters &&
          this.internalFilters[param].length === 0
        ) {
          delete this.internalFilters[param];
        }
      });
      this.$emit("saved", this.cloneDeep(this.internalFilters));
      this.showing = false;
    },
    cancel() {
      this.showing = false;
    }
  }
};
</script>
